$maxDesktopFinalValue: 2560px;
$maxMobile: 1024px;

$minimumPaddingDesktop: 48px;
$paddingDesktop: max(48px, ((100vw - 2560px)/2));
$paddingMobile: 20px;

$maxDesktop: $maxDesktopFinalValue + (2 * $minimumPaddingDesktop);

$headerHeight: 100px;

@mixin maxContentWidth {
  width: 100%;
  max-width: $maxDesktop;
  margin: auto;
}

@mixin maxGridWidth {
  width: 100%;
  max-width: $maxDesktopFinalValue;
  margin:auto;
}

@mixin mobile {
  //medium width and down
  @media (max-width: #{$maxMobile}) {
    @content;
  }
}

@mixin desktop {
  //medium width and up
  @media (min-width: #{$maxMobile}) {
    @content;
  }
}

@mixin desktopPlus {
  @media (min-width: #{$maxDesktopFinalValue}) {
    @content;
  }
}

