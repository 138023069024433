@use "@/styles/breakpoint";
@use "@/styles/colors";

$defaultLineHeight: 1.5;
$defaultWeight: 300;


@mixin underline {
  text-decoration: underline;
  text-underline-offset: 3px;
}

@mixin clearMargins($lineHeight, $fontSize){
  margin-top: calc((($lineHeight - 1) / 2) * $fontSize * -1px);
  margin-bottom: calc((($lineHeight - 1) / 2) * $fontSize * -1px);
}

@mixin fontSetter(
    $desktop, 
    $mobile: $desktop, 
    $plus: $desktop, 
    $noMargin: false,
    $lineHeight: $defaultLineHeight, 
    $weight: $defaultWeight, 
    $lineHeightMobile: $lineHeight, 
    $lineHeightPlus: $lineHeight, 
    $weightMobile: $weight, 
    $weightPlus: $weight
  ) {
  font-size: calc($desktop / 10 * 1rem);
  line-height: $lineHeight;
  font-weight: $weight;
  @if $noMargin {
    @include clearMargins($lineHeight, $desktop);
  }
  @include breakpoint.mobile {
    font-size: calc($mobile / 10 * 1rem);
    line-height: $lineHeightMobile;
    font-weight: $weightMobile;
    @if $noMargin {
      @include clearMargins($lineHeightMobile, $mobile);
    }
  }
  @include breakpoint.desktopPlus {
    font-size: calc($plus / 10 * 1rem);
    line-height: $lineHeightPlus;
    font-weight: $weightPlus;
    @if $noMargin {
      @include clearMargins($lineHeightPlus, $plus);
    }
  }
}

@mixin h48-16($noMargin: false) {
  @include fontSetter(48, 16, 48, $noMargin);
}

@mixin h40-32($noMargin: false) {
  @include fontSetter(40, 32, 48, $noMargin, 1.3);
}

@mixin h32-22($noMargin: false) {
  @include fontSetter(32, 22, 40, $noMargin, 1.3);
}

@mixin h24-20($noMargin: false) {
  @include fontSetter(24, 20, 32, $noMargin);
}

@mixin h20-16($noMargin: false) {
  @include fontSetter(20, 16, 24, $noMargin);
}

@mixin b16-16 {
  @include fontSetter(16);
}

@mixin b16-14($noMargin: false) {
  @include fontSetter(16, 14, 18, $noMargin);
}

@mixin b14-14($noMargin: false) {
  @include fontSetter(14, 14, 16, $noMargin);
}
@mixin b14-12($noMargin: false) {
  @include fontSetter(14, 12, 16, $noMargin)
}

@mixin b12-12 {
  @include fontSetter(12);
}

@mixin b12-10($noMargin: false) {
  @include fontSetter(12, 10, 14, $noMargin)
}

@mixin caption {
  @include fontSetter(10,10,12)
}

@mixin captionCaps {
  @include caption;
  text-transform: uppercase;
}