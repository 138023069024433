@use "@/styles/_typography.scss";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 80vh;
    width: 100%;
    gap: 1rem;
}

.title{
  @include typography.h32-22;
  text-align: center;
  display: block;
  width: 100%;
  text-decoration: none;
}